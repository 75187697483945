import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getUnidadeLocal } from "../../../methods/get/getUnidadeLocal";
import { putHide } from "../../../methods/put/putHide";
import { PropsHide } from "./types";
import './Hide.sass'
import { ButtonDelete } from "../../ButtonsCustom";

export function HideCard(props: PropsHide) {

    const id = props.id
    const [uni, setUnidade] = useState('')

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: '#311042',
        borderRadius: '12px',
        boxShadow: 24,
        p: 4,
        '@media (max-width: 900px)': {
            width: '70%'
        }
    };

    useEffect(() => {
        getUnidadeLocal(setUnidade)
    }, [uni])


    if (props.ativar === false) {

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.openHide}
                onClose={props.handleCloseHide}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openHide}>
                    <Box sx={style}>
                        <div className="hide-title">
                            <h3>Ocultar Produto</h3>
                        </div>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Tem certeza que deseja ocultar este produto ?
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, }}>
                            O mesmo continuará disponivel na seção "Produtos Ocultos".
                        </Typography>
                        <div style={{ display: 'flex', margin: '10px', justifyContent: 'flex-end' }}>
                            <ButtonDelete onClick={() => putHide(id, true)} sx={{ margin: '10px' }} variant="contained" color='inherit'>Ocultar</ButtonDelete>
                            <Button onClick={props.handleCloseHide} sx={{ margin: '10px' }} variant='outlined'>Sair</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        )
    } if (props.ativar === true) {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.openHide}
                onClose={props.handleCloseHide}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openHide}>
                    <Box sx={style}>
                    <div className="hide-title">
                            <h3>Ativar Produto</h3>
                        </div>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Tem certeza que deseja ativar este produto ?
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, }}>
                            O mesmo continuará disponivel na seção "Produtos Ativos".
                        </Typography>
                        <div style={{ display: 'flex', margin: '10px', justifyContent: 'flex-end' }}>
                            <ButtonDelete onClick={() => putHide(id, false)} sx={{ margin: '10px' }} variant="contained" color='inherit'>Ativar</ButtonDelete>
                            <Button onClick={props.handleCloseHide} sx={{ margin: '10px' }} variant='outlined'>Sair</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        )

    } else {
        return (
            <div></div>
        )
    }

}