import { Button, Divider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { urlServidor } from "../../methods/db";
import Home from "../Home/Home";
import "./Unidade.sass";

interface Unidade {
  id: number;
  unidade: string;
}

export default function Unidade() {
  const [choiceuni, setChoiceUni] = useState("");
  const [nameUnidade, setNameUnidade] = useState("");
  const [redirect, setRedirect] = useState(false);

  const [unidade, setUnidade] = useState<Unidade[]>([
    {
      id: 0,
      unidade: "Carregando...",
    },
  ]);
  const toHome = () => setRedirect(true);

  async function getNameUnid() {
    try {
      return await axios
        .get(`${urlServidor}/acessos/getAcessos`)
        .then((data) => {
          setUnidade(data.data);
        });
    } catch (error) {
      return console.log(error);
    }
  }

  useEffect(() => {
    getNameUnid();
  }, []);

  if (redirect) {
    if (choiceuni) {
      return <Home uni={choiceuni} name={nameUnidade} />;
    }
  }

  return (
    <div className="unidade-body-full">
      <div className="unidade-body">
        <div className="unidade-title">
          <h2>Bem vindo a CODEBANK</h2>
          <p>Selecione sua unidade abaixo</p>
          <Divider color="white" />
        </div>
        <div className="unidade-buttons">
          {unidade.map((i) => {
            if (+i.id !== 1) {
              return (
                <Button
                  key={i.id.toString()}
                  onClick={() => {
                    setChoiceUni(i.id.toString());
                    setNameUnidade(i.unidade.toString());
                    toHome();
                  }}
                >
                  {i.unidade.toString()}
                </Button>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
