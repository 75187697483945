import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, IconButton } from '@mui/material';
import './Card.sass'
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { urlServidor } from '../../methods/db';
import { ButtonSix } from '../ButtonsCustom';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { EditarModal } from './ModalCards/Editar';
import { DeleteModal } from './ModalCards/Delete';
import { HideCard } from './ModalCards/Hide';
import codeCoin from '../../imgs/code-coin.png'

export default function CardsDesativados(props: any) {

    const [openEdit, setOpenEdit] = React.useState(false);
    const handleCloseEdit = () => setOpenEdit(false);

    const [openHide, setOpenHide] = React.useState(false);
    const handleCloseHide = () => setOpenHide(false);

    const [openDelete, setOpenDelete] = React.useState(false);
    const handleCloseDelete = () => setOpenDelete(false);

    const urlImg = urlServidor + props.url_img


    let hidden = props.hide

    if (hidden === true ) {
        return (
            <Card sx={{
                width: '100%',
                borderRadius: '14px',
                backgroundColor: '#84738c',
                border: 'solid 2px #ffffff',
                '@media (max-width:1400px)': {
                    height:'65vh'
                },
                '@media (max-width:1600px)': {
                    height:'61vh'
                },
                '@media (max-width:900px)': {
                    width: '100%'
                },
                '@media (max-width:380px)': {
                    height: '80vh'
                }

            }}>
                <CardMedia
                    component="img"
                    height="190"
                    image={urlImg}
                    alt="Recompensa"
                />
                <CardContent
                    sx={{
                        backgroundColor: '#84738c',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '28vh'
                    }}
                >
                    <h2>{props.title}</h2>

                    <p>{props.description}</p>

                    <div className='card-stock-disable'>
                        <Inventory2Icon sx={{ color: 'white' }} />
                        <h4>{props.stock}</h4>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <div className='card-coins'  >
                            <img src={codeCoin} alt='codeCoin' style={{width: '50px', marginRight: '20px'}} />
                            <h3>{props.money}</h3>
                        </div>

                        <Button disabled size='small'
                            sx={{
                                color: '#3f1953',
                                backgroundColor: '#fff',
                                borderRadius: '12px',
                                '&:hover': {
                                    backgroundColor: '#E50051',
                                    color: '#fff',
                                }
                            }} >Reservar</Button>

                    </div>

                    <div>
                        {props.showLinks ? (
                            <div className='card-actions-hide'>
                                <IconButton onClick={() => setOpenHide(true)} sx={{ padding: '0' }}>
                                    <VisibilityOffIcon
                                        sx={{
                                            backgroundColor: "#3f1953",
                                            color: '#fff',
                                            fontSize: '35px',
                                            borderBottom: '4px solid #ffffff',
                                            width: '50px',
                                            borderRadius: '12px',
                                            '&:hover': {
                                                backgroundColor: '#2c123a'
                                            }
                                        }}
                                    />
                                </IconButton>
                                <ButtonSix variant='contained'
                                    sx={{
                                        backgroundColor: 'red',
                                        color: 'white'
                                    }}
                                    startIcon={
                                        <DeleteIcon sx={{
                                            color: 'white',
                                        }} />
                                    }
                                    onClick={() => setOpenDelete(true)}
                                >

                                    Apagar
                                </ButtonSix>
                            </div>
                        ) : <div></div>}
                    </div>

                </CardContent>
                <EditarModal
                    openEdit={openEdit}
                    handleCloseEdit={handleCloseEdit}
                    url_img={props.url_img}
                    title={props.title}
                    description={props.description}
                    stock={props.stock}
                    money={props.money}
                    id={props.id}
                />

                <HideCard
                    openHide={openHide}
                    handleCloseHide={handleCloseHide}
                    id={props.id}
                    ativar={true}

                />

                <DeleteModal
                    openDelete={openDelete}
                    handleCloseDelete={handleCloseDelete}
                    url_img={props.url_img}
                    id={props.id}
                />
            </Card>
        );
    } else {
        return (
            <></>
        )
    }
}