export function setUnidade(setUni: any) {
    const json = localStorage.getItem('u')

    if (!json) {
        return null
    }

    const user = JSON.parse(json)
    const unidade = user.id_ace


    if(unidade) {
        setUni(unidade)
    } 
    
}