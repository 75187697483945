import { useEffect, useState } from "react";
import "./Home.sass";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CardsAtivos from "../../components/Card/CardsAtivos";
import { getCards } from "../../methods/get/getCards";

interface Dados {
  id: number;
  url_img: string;
  title: string;
  description: string;
  money: number;
  stock: number;
  hide: boolean;
  approved: boolean;
}

export default function Home(home: any) {
  const [dados, setDados] = useState<Dados[]>([
    {
      id: 0,
      url_img: "",
      title: "",
      description: "",
      money: 0,
      stock: 0,
      hide: false,
      approved: false,
    },
  ]);

  useEffect(() => {
    getCards(setDados, home.uni);
  }, [home.uni]);

  return (
    <div>
      <Header />
      <div className="home-body-full">
        <div className="home-body">
          <div className="home-banner" />
          <h1>Recompensas Disponiveis apenas para a unidade de {home.name}!</h1>

          <div className="home-cards">
            {dados.map((d) => {
              return (
                <CardsAtivos
                  key={d.id}
                  title={d.title}
                  url_img={d.url_img}
                  description={d.description}
                  money={d.money}
                  stock={d.stock}
                  hide={d.hide}
                  approved={d.approved}
                  id={d.id}
                  uni={home.uni}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
