import axios from "axios";
import {urlServidor } from "../db";

export async function getUnidade(setNameUnid: any, setEnd: any) {
    const dados = localStorage.getItem('u')

    if (!dados) {
        return null
    }

    const unidadeObj = JSON.parse(dados)
    const unidade = unidadeObj.id_ace

    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    };

    await axios.get(`${urlServidor}/acessos/getUnidade/${unidade}`, config)
        .then((requisicao) => {
            const data = requisicao.data[0]
            setNameUnid(data.unidade)
            setEnd(data.end_unidade)
        })
}