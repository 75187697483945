import React, { useEffect, useState } from "react";
import { getUnidadeLocal } from "../../../methods/get/getUnidadeLocal";
import { getCards } from "../../../methods/get/getCards";
import "./ViewProdutcs.sass";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CardsAtivos from "../../../components/Card/CardsAtivos";
import CardsDesativados from "../../../components/Card/CardsDesativados";
import CardsAguardando from "../../../components/Card/CardsAguardando";

export default function ViewProducts() {
  const [showLinks, setShowLinks] = React.useState(true);

  const [unidade, setUnidade] = useState("");

  const [dados, setDados] = useState([
    {
      id: Number,
      url_img: String,
      title: String,
      description: String,
      money: Number,
      stock: Number,
      hide: Boolean,
      approved: Boolean,
    },
  ]);

  useEffect(() => {
    getUnidadeLocal(setUnidade);
    getCards(setDados, unidade);
  }, [unidade]);

  //PRA BAIXO GROUP

  const options = [
    "Produtos Ativos",
    "Produtos Desativados",
    "Aguardando Aprovação",
  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  function switchCards(index: number) {
    if (index === 0) {
      return (
        <div className="home-cards">
          {dados.map((d) => {
            return (
              <CardsAtivos
                key={d.id}
                title={d.title}
                url_img={d.url_img}
                description={d.description}
                money={d.money}
                stock={d.stock}
                id={d.id}
                setShowLinks={setShowLinks}
                showLinks={showLinks}
                hide={d.hide}
                approved={d.approved}
              />
            );
          })}
        </div>
      );
    }
    if (index === 1) {
      return (
        <div className="home-cards">
          {dados.map((d) => {
            return (
              <CardsDesativados
                key={d.id}
                title={d.title}
                url_img={d.url_img}
                description={d.description}
                money={d.money}
                stock={d.stock}
                id={d.id}
                setShowLinks={setShowLinks}
                showLinks={showLinks}
                hide={d.hide}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="home-cards">
          {dados.map((d) => {
            return (
              <CardsAguardando
                key={d.id}
                title={d.title}
                url_img={d.url_img}
                description={d.description}
                money={d.money}
                stock={d.stock}
                id={d.id}
                setShowLinks={setShowLinks}
                showLinks={showLinks}
                hide={d.hide}
                approved={d.approved}
              />
            );
          })}
        </div>
      );
    }
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="vp-full-body">
      <div className="home-body">
        <div>
          <ButtonGroup
            sx={{ margin: "20px 10px 20px 10px" }}
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button>{options[selectedIndex]}</Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        {switchCards(selectedIndex)}
      </div>
    </div>
  );
}
