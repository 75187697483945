import React, { useState } from "react";
import "./GetAlunosAdd.sass";
import { PurpleField, PurpleFieldWhite } from "../../../components/PurlpleText";
import SearchIcon from "@mui/icons-material/Search";
import userProfile from "../../../imgs/user-profile.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, IconButton } from "@mui/material";
import axios from "axios";
import { getUser } from "../../../methods/get/getUser";
import { urlServidor } from "../../../methods/db";
import manivela from "../../../imgs/manivela.png";
import codeCoin from "../../../imgs/code-coin.png";

interface User {
  fullname: string;
  username: string;
  city: string;
  customfields: { value: string }[];
  id: number;
}

interface BdCodeBanck {
  id: number;
  id_aluno_moodle: number;
  saldo: number;
  nome_aluno: string;
}

export default function GetAlunosAdd(props: any) {
  const [saldoAtual, setSaldoAtual] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [textMessage, setTextMessage] = React.useState("");

  const [user, setUser] = useState<User>({
    fullname: "",
    username: "",
    city: "",
    customfields: [
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ],
    id: 0,
  });

  const [bdCodeBanck, setBdCodeBanck] = useState<BdCodeBanck>({
    id: 0,
    id_aluno_moodle: 0,
    saldo: 0,
    nome_aluno: "",
  });

  const [showLinks, setShowLinks] = useState(false);

  const showItems = () => setShowLinks(true);

  async function putSaldo(saldoAtual: any, textMessage: any) {
    if (saldoAtual === undefined) {
      alert("Adicione o numero de CodeCoins necessarias!");
    }
    if (textMessage === "") {
      alert("Preencha uma justificativa valida!");
    } else {
      const saldoFinal = bdCodeBanck.saldo + saldoAtual;

      await axios.put(`${urlServidor}/alunos/updateBalance/${user.id}`, {
        saldo: saldoFinal,
      });

      getUser(username, setBdCodeBanck, setUser, showItems);

      await axios
        .post(`${urlServidor}/email/send-email`, {
          textmail: textMessage,
          unidade: props.nameUnid,
          alunos: username
        })
        .then(() =>
          alert(
            "E-mail enviado para Franchising, e moedas atribuidas ao Aluno!"
          )
        )
        .catch((erro) =>
          alert("Ocorreu um erro ao enviar o e-mail a Franchising")
        );
    }
  }

  return (
    <div className="get-body">
      <div className="get-card">
        <div className="get-title">
          <h4>Pesquisa de Alunos | Atribuir CodeCoins</h4>
        </div>
        <div className="get-serch">
          <PurpleField
            label={
              <p style={{ display: "flex", alignItems: "center" }}>
                <SearchIcon />
                Pesquisar Alunos
              </p>
            }
            value={username}
            fullWidth
            onChange={(e) => setUsername(e.target.value)}
          />
          <IconButton
            onClick={() =>
              getUser(username, setBdCodeBanck, setUser, showItems)
            }
          >
            <SearchIcon
              sx={{
                color: "white",
                backgroundColor: "#E50051",
                padding: "15px",
                borderRadius: "12px",
                ":hover": {
                  backgroundColor: "white",
                  color: "#3f1953",
                },
              }}
            />
          </IconButton>
        </div>
        <div className="get-section-aluno">
          {showLinks ? (
            <div className="get-info-aluno">
              <div>
                <img
                  className="get-user-picture"
                  src={userProfile}
                  alt="userPrifle"
                />
                <h2>{user.fullname?.toString()}</h2>
              </div>
              <div>
                <h2>
                  {user.customfields[5]
                    ? user.customfields[5].value?.toString()
                    : "Unidade não informada!"}
                </h2>
                <h2>{user.city?.toString()}</h2>
              </div>

              <div className="get-user-saldo">
                <FormControl fullWidth>
                  <InputLabel color="secondary" id="demo-simple-select-label">
                    Adicionar CodeCoins
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={saldoAtual}
                    label="Age"
                    onChange={(e) => setSaldoAtual(e.target.value)}
                    color="secondary"
                    sx={{ width: "80%" }}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={75}>75</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={125}>125</MenuItem>
                  </Select>
                </FormControl>
                <div>
                  <img
                    src={codeCoin}
                    alt="codeCoin"
                    style={{ width: "50px" }}
                  />
                  <h5>
                    {bdCodeBanck.saldo?.toString()
                      ? bdCodeBanck.saldo?.toString()
                      : "Sem Saldo"}
                  </h5>
                </div>
              </div>
              <div className="get-user-space-button">
                <PurpleFieldWhite
                  label="Justificativa p/ CodeCoin"
                  variant="outlined"
                  value={textMessage}
                  fullWidth
                  onChange={(e) => setTextMessage(e.target.value)}
                />
              </div>
              <Button
                variant="contained"
                color="secondary"
                sx={{marginBottom: '4%'}}
                onClick={() => putSaldo(saldoAtual, textMessage)}
              >
                Adicionar ao saldo
              </Button>
            </div>
          ) : (
            <div className="get-info-aluno">
              <div className="get-user-manivela">
                <img src={manivela} width="200px" alt="manivela" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
