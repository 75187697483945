export async function getUnidadeLocal(setUnidade: any) {

     const json = await localStorage.getItem('u')

    if (!json) {
        return null
    }
    const user = JSON.parse(json)

    if (user === null) {
        return console.log('erro')
    } else {
        const uni = user.id_ace
        setUnidade(uni)
    }

}