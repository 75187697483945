import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import './Card.sass'
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { urlServidor } from '../../methods/db';
import { ButtonAccept, ButtonEdit, ButtonReject } from '../ButtonsCustom';
import { EditarModal } from './ModalCards/Editar';
import { AprovarCard } from './ModalCards/Aprovar';
import { RejeitarCard } from './ModalCards/RejeitarCard';
import codeCoin from '../../imgs/code-coin.png';

export default function CardsAguardando(props: any) {

    const [openEdit, setOpenEdit] = React.useState(false);
    const handleCloseEdit = () => setOpenEdit(false);

    const [openReject, setOpenReject] = React.useState(false);
    const handleCloseReject = () => setOpenReject(false);

    const [openAprovar, setOpenAprovar] = React.useState(false);
    const handleCloseAprovar = () => setOpenAprovar(false);

    const urlImg = urlServidor + props.url_img
    let approved = props.approved

    if (approved === false) {
        return (
            <Card sx={{
                width: '100%',
                borderRadius: '14px',
                backgroundColor: '#84738c',
                border: 'solid 2px #ffffff',
                '@media (max-width:900px)': {
                    width: '100%'
                }
            }}>
                <CardMedia
                    component="img"
                    height="190"
                    image={urlImg}
                    alt="Recompensa"
                />
                <CardContent
                    sx={{
                        backgroundColor: '#84738c',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '28vh'
                    }}
                >
                    <h2>{props.title}</h2>

                    <p>{props.description}</p>

                    <div className='card-stock-disable'>
                        <Inventory2Icon sx={{ color: 'white' }} />
                        <h4>{props.stock}</h4>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                        <div className='card-coins'  >
                            <img src={codeCoin} alt='codeCoin' style={{width: '50px', marginRight: '20px'}} />
                            <h3>{props.money}</h3>
                        </div>

                        <Button disabled size='small'
                            sx={{
                                color: '#3f1953',
                                backgroundColor: '#fff',
                                borderRadius: '12px',
                                '&:hover': {
                                    backgroundColor: '#E50051',
                                    color: '#fff',
                                }
                            }} >Reservar</Button>

                    </div>
                    {props.hideActions ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        <ButtonAccept onClick={() => setOpenAprovar(true)}>Aprovar</ButtonAccept>
                        <ButtonReject onClick={() => setOpenReject(true)}>Rejeitar</ButtonReject>
                        <ButtonEdit onClick={() => setOpenEdit(true)}>Editar</ButtonEdit>
                    </div>

                    ): (<></>)} 
                </CardContent>
                <AprovarCard
                    openAprovar={openAprovar}
                    handleCloseAprovar={handleCloseAprovar}
                    id={props.id}
                    ativar={true}
                    uni={props.uni}
                />

                <RejeitarCard
                    openReject={openReject}
                    handleCloseReject={handleCloseReject}
                    id={props.id}
                    uni={props.uni}
                    pathImg={props.url_img}
                />

                <EditarModal
                    openEdit={openEdit}
                    handleCloseEdit={handleCloseEdit}
                    url_img={props.url_img}
                    title={props.title}
                    description={props.description}
                    stock={props.stock}
                    money={props.money}
                    id={props.id}
                    uni={props.uni}
                />
            </Card>
        );
    } else {
        return (
            <></>
        )
    }
}