import React, { useState } from "react";
import { ButtonPrimary } from "../../components/ButtonsCustom";
import { PurpleField } from "../../components/PurlpleText";
import Header from "../../components/Header/Header";
import "../Login/Login.sass";
import logo from "../../imgs/logo.png";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  const auth = useAuth();

  async function onFinish(email: string, password: string) {
    try {
      await auth.authenticate(email, password);
      isLogged();
    } catch (error) {
      alert("Email ou senha invalida");
    }
  }

  function isLogged() {
    setRedirect(true);
  }

  if (redirect) {
    const json = localStorage.getItem("u");

    if (!json) {
      return null;
    }

    const user = JSON.parse(json);

    if (user.email.includes("coord")) {
      return <Navigate to="/PainelCoord" />;
    } else {
      return <Navigate to="/Alvorada" />;
    }
  }

  return (
    <div>
      <Header />
      <div className="login-body">
        <form className="login-card">
          <img src={logo} alt="logo-code8734" />
          <h3>Entre com o acesso da unidade!</h3>
          <div className="login-inputs">
            <PurpleField
              label={<p>Login</p>}
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <PurpleField
              label={<p>Senha</p>}
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="login-acces">
            <h5>Esqueceu o acesso da unidade?, nos informe!</h5>
          </div>

          <ButtonPrimary
            onClick={() => onFinish(email, password)}
            fullWidth
            variant="contained"
          >
            LOGIN
          </ButtonPrimary>
        </form>
        <div className="login-card-message">
          <h2>
            Sua unidade ainda não possui um acesso? entre em contato com a
            franqueadora pelos canais oficiais!
          </h2>
        </div>
      </div>
      <Footer />
    </div>
  );
}
