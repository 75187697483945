import { TextField, styled } from "@mui/material";

export const PurpleField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiOutlinedInput-input': {
        color: 'white'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {

        '& fieldset': {
          borderColor: '#d3d3d3',
          color: 'white'
        },
        '&:hover fieldset': {
          borderColor: 'purple',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
          
        },
      },
})

export const PurpleFieldWhite = styled(TextField)({
  '& label.Mui-focused': {
      color: 'purple',
    },
    '& .MuiOutlinedInput-input': {
      color: '#4e4e4e'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'purple',
    },
    '& .MuiOutlinedInput-root': {

      '& fieldset': {
        borderColor: '#d3d3d3',
        color: 'purple'
      },
      '&:hover fieldset': {
        borderColor: 'purple',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'purple',
        
      },
    },
})