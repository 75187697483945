import axios from "axios";
import qs from 'qs';
import { token, urlServidor } from "../db";

export async function PutStock(uni: string, id: NumberConstructor, stock: number, idSolicitacao:any, saldoCc: any, id_moodle: any) {
    return await axios.put(`${urlServidor}/produtos/geral/putStock/${id}`, {
        stock: stock
    })  
        .then(() => {
            PutCodeCoin(saldoCc, id_moodle)
            delSolicitacao(uni,idSolicitacao)

        }).then(async () => {

            const config = {
                url: `https://ava.code8734.com.br/webservice/rest/server.php?wstoken=${token}&wsfunction=core_message_send_instant_messages&moodlewsrestformat=json`,
                method: 'post',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: qs.stringify({
                    messages: [{
                        touserid: id_moodle,
                        text: "Parabéns, o seu pedido foi finalizado com sucesso, entre em contato com seu instrutor para recuperar o item!",
                        textformat: 1
                    }]
                })
              };

            axios(config)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
            
        })

        .catch(() => {
            alert('Ocorreu um erro, por favor tente novamente!')
        })
}

export async function delSolicitacao(uni:string, idSolicitacao: NumberConstructor) {
    
    await axios.delete(`${urlServidor}/geral/deleteSolicitacao/${idSolicitacao}`)
        .then(() => {
            alert('Solicitacão Finalizada com sucesso')
            return window.location.reload()
        })
        .catch(() => alert('Ocorreu um erro de Rede, tente novamente!'))
}

export async function PutCodeCoin( saldoCc: any, id_moodle: any) {
    return await axios.put(`${urlServidor}/alunos/updateBalance/${id_moodle}`, {
        saldo: saldoCc
    })  
        .then((dados) => console.log(dados))
        .catch(() => {
            alert('Ocorreu um erro, por favor tente novamente!')
        })
}