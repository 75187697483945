import axios from "axios";
import { urlServidor } from "../db";

export async function PostSolicitacao(username: string, id_product: Number, uni: String, bdsaldo: NumberConstructor, money: Number, id_aluno_moodle: NumberConstructor) {

    if (+bdsaldo >= money) {
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
            }
        };

        try {
            await axios.post(`${urlServidor}/geral/cadSolicitacoes`, {
                username: username,
                id_product: id_product,
                id_moodle: id_aluno_moodle,
                id_unidade: uni,
            }, config);
            alert('Solicitação recebida com sucesso!, entre em contato com seu instrutor!');
            return window.location.reload();
        } catch (erro) {
            console.log(erro);
            return alert('Ocorreu um erro de rede, tente novamente mais tarde!');
        }
    } else {
        return alert(`O aluno ${username}, não possui as CodeCoins necessarias para a solicitação!`)
    }
}




