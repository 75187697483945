import React, { useEffect, useState } from "react";
import { getCards } from "../../../methods/get/getCards";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CardsAguardando from "../../../components/Card/CardsAguardando";
import axios from "axios";
import { urlServidor } from "../../../methods/db";


export default function ProductsWaiting() {
    const [showLinks, setShowLinks] = React.useState(true)

    const [unidade, setUnidade] = useState(0)

    const [nameUnid, setNameUnid] = useState([{
        id: Number,
        unidade: String,
    }])

    const [dados, setDados] = useState([{
        id: Number,
        url_img: String,
        title: String,
        description: String,
        money: Number,
        stock: Number,
        hide: Boolean,
        approved: Boolean
    }])

    async function getNameUnid() {
        try {
            return await axios.get(`${urlServidor}/acessos/getAcessos`)
                .then((data) => {
                    setNameUnid(data.data)
                })
        } catch (error) {
            return console.log(error)
        }
    }

    useEffect(() => {
        getCards(setDados, unidade)
        getNameUnid()

    }, [unidade])


    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);


    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);

    };

    useEffect(() => {
        setUnidade(selectedIndex)
    },[selectedIndex])


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <div className="vp-full-body">
            <div className="home-body">
                <div>
                    <ButtonGroup sx={{ margin: '20px 10px 20px 10px' }} variant="contained" ref={anchorRef} aria-label="split button">
                        <Button>Selecione a Unidade</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {nameUnid.map((i) => (
                                                <MenuItem
                                                    key={i.unidade.toString()}
                                                    disabled={+i.id === 1}
                                                    selected={+i.unidade === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, +i.id)}
                                                >
                                                    {i.unidade.toString()}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>

                <div className="home-cards">
                    {dados.map((d) => {
                        return (
                            <CardsAguardando
                                key={d.id}
                                title={d.title}
                                url_img={d.url_img}
                                description={d.description}
                                money={d.money}
                                stock={d.stock}
                                id={d.id}
                                setShowLinks={setShowLinks}
                                showLinks={showLinks}
                                hide={d.hide}
                                approved={d.approved}
                                uni={unidade}
                                hideActions={true}
                            />
                        )
                    })}
                </div>

            </div>
        </div>
    )
}