import { Api } from "../services/api"
import { IUser } from "./types"

export function setUserLocalStorage (user: IUser | null) {
    localStorage.setItem('u', JSON.stringify(user))
}

export function getUserLocalStorage () {
    const json = localStorage.getItem('u')

    if (!json) {
        return null
    }

    const user = JSON.parse(json)

    return user ?? null 
}

export async function LoginRequest (email: string, password: string) {
    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    };
    
    try {
        const request = await Api.post('/acessos/login', {email, password}, config)
        return request.data

    } catch (error) {
        return null
    }
}