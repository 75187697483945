import axios from "axios";
import { urlServidor } from "../db";

export async function putApproved(id: Number, approved: boolean) {

    await axios.put(`${urlServidor}/produtos/geral/approved/${id}`, {
        approved: approved
    })
        .then(() => {
            alert('Produto aprovado com sucesso!')
            return window.location.reload()
        })

        .catch(() => {
            alert('Ocorreu um erro, por favor tente novamente!')
        })
}