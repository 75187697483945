import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../imgs/logo.png'
import '../HeaderInstrutor/HeaderInstrutor.sass'
import { styled } from '@mui/system';
import { ButtonThird } from '../ButtonsCustom';
import { ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import userProfile from '../../imgs/user-profile.png'
import { useAuth } from '../../context/AuthProvider/useAuth';
import { getProfissao } from '../../methods/get/getProfissao';
import { useNavContext } from '../../context/NavProvider';

const drawerWidth = 240;

const AppBarCustom = styled(AppBar)`
    background-color: #311042;
    box-shadow: none;
    width: 100%;
    display: flex;  
    align-items: center;
    justify-content: center;
    height: 8vh;
    overflow: hidden;

    @media screen and (max-width: 900px) {
        height: 9vh;
    }

`

const ToolbarCustom = styled(Toolbar)`
    display: flex;
    background-color: #311042;
    width: 98%;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
        justify-content: space-between;
        width: 90%;
        align-items: center;
        
    }

`

export default function HeaderInstructor(props: any) {

    const auth = useAuth()

    const [profissao, setProfissao] = React.useState('Usuário')

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => setAnchorEl(event.currentTarget);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);

    const { setNumberPag } = useNavContext()

    React.useEffect(() => {
        getProfissao(setProfissao)
    }, [])


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{
            textAlign: 'center',
            backgroundColor: '#311042',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Link to='/'><img className='logo-header-mob' src={logo} alt='logo' width='170px' height='35px' /></Link>

            <hr style={{ color: 'white', width: '80%' }} />
            <List>
                <ListItemButton sx={{ display: 'flex', flexDirection: 'column' }}>
                    <a style={{ textDecoration: 'none' }} href='https://ava.code8734.com.br/'><ButtonThird>AVA</ButtonThird></a>
                    <a style={{ textDecoration: 'none' }} href='https://code8734.com.br/'><ButtonThird>CODE8734</ButtonThird></a>
                    <ButtonThird onClick={() => setNumberPag(0)}>Atribuir CodeCoins</ButtonThird>
                    <ButtonThird onClick={() => setNumberPag(1)}>Retirar CodeCoins</ButtonThird>
                    <ButtonThird onClick={() => setNumberPag(8)}>Atribuir CodeCoins a Grupo</ButtonThird>
                    {props.unityButtons ? (
                        <>
                            <ButtonThird onClick={() => setNumberPag(3)}>Cadastrar Produto</ButtonThird>
                            <ButtonThird onClick={() => setNumberPag(6)}>Listar Pedidos</ButtonThird>
                            <ButtonThird onClick={() => setNumberPag(4)}>Listar Produtos</ButtonThird>
                        </>
                    ) : (
                        <>
                            <ButtonThird onClick={() => setNumberPag(2)}>Cadastrar Unidade</ButtonThird>
                            <ButtonThird onClick={() => setNumberPag(5)}>Aguardando Aprovação</ButtonThird>
                            <ButtonThird onClick={() => setNumberPag(7)}>Alterar dados das Unidades</ButtonThird>
                        </>
                    )

                    }
                </ListItemButton>
            </List>
        </Box>
    );


    return (
        <div style={{ display: 'flex' }}>
            <AppBarCustom position='static'>
                <ToolbarCustom>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: 'none' }, }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Link to='/'><img className='logo-header-inst' src={logo} alt='logo' width='200px' height='35px' /></Link>

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <a style={{ textDecoration: 'none' }} href='https://ava.code8734.com.br/'><ButtonThird>AVA</ButtonThird></a>
                        <a style={{ textDecoration: 'none' }} href='https://code8734.com.br/'><ButtonThird>CODE8734</ButtonThird></a>
                        <Link style={{ textDecoration: 'none' }} to='/'><ButtonThird>RECOMPENSAS</ButtonThird></Link>
                    </Box>

                    <Stack direction='row' sx={{ alignItems: 'center' }} >
                        <Typography color='primary' className="text-user">Seja bem vindo!, {profissao}</Typography>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <img style={{ borderRadius: '80px', height: '50px', borderColor: 'red', borderStyle: 'solid', border: '5px' }}
                                src={userProfile} alt='userProfile' referrerPolicy="no-referrer"
                            />
                        </IconButton>
                    </Stack>
                </ToolbarCustom>
            </AppBarCustom>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <ListItemIcon onClick={() => auth.logout()}>
                            <Logout fontSize="small" />
                            Sair
                        </ListItemIcon>
                    </Link>
                </MenuItem>
            </Menu>

            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </div>
    );
}