import { Button, Typography } from "@mui/material";
import { ButtonDelete } from "../../../components/ButtonsCustom";
import { delProductCard } from "../../../methods/delete/delProductCard";
import './DelProduct.sass'

export default function DelProduct(props: any) {
    const id = props.id
    const img = props.img.slice(15)

    return (
        <div >
            <div className='delproduct-body'>
                <h2 className="delproduct-title" >Apagar Produto</h2>

                <div className="delproduct-text">
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Tem certeza que deseja excluir este Produto?
                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2, }}>
                        Esta decisão NÃO TEM VOLTA!
                    </Typography>
                </div>
                <div className="delproduct-buttons">
                    <ButtonDelete onClick={() => delProductCard(id, img)} >SIM</ButtonDelete>
                    <Button onClick={() => props.handleCloseDelete()} variant='outlined'>NÃO</Button>
                </div>
            </div>
        </div>
    )
}