export function getProfissao(setProfissao: any) {
    const dados = localStorage.getItem('u')

    if (!dados) {
        return null
    }

    const unidadeObj = JSON.parse(dados)

    if (unidadeObj.email.includes('adm')) {
        setProfissao('Admin')
    } else {
        setProfissao('Coordenador')
    }
}