import axios from "axios";
import { useState } from "react";
import { urlServidor } from "../../../methods/db";
import { ButtonDelete, ButtonPrimary } from "../../ButtonsCustom";
import { PurpleField } from "../../PurlpleText";
import './ChangePasswordUni.sass'

export default function ChangePasswordUni(props: any) {
    const [password, setPassword] = useState('')

    async function putPassword(email:string, password: string) {
        try{
            await axios.put(`${urlServidor}/acessos/putPassword`, {
                email: email,
                password: password
            })
                .then(() => {
                    alert("Senha Alterada com sucesso!")
                    props.handleClose()
            })
        } catch (erro) {
            console.log(erro)
            return alert("Ocorreu um erro de rede, tente novamente mais tarde!")
        }
    }

    return (
        <div className="change-pu-body">
            <div>
                <h3 className="change-pu-title">Alterar Senha</h3>
            </div>
            <div>
                <PurpleField
                    fullWidth
                    label={<p>Digite a nova senha</p>}
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div className="change-pu-buttons">
                <ButtonDelete onClick={() => putPassword(props.email, password)}>Alterar</ButtonDelete>
                <ButtonPrimary onClick={() => props.handleClose()} >Cancelar</ButtonPrimary>
            </div>
        </div>
    )
}