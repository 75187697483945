import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { PurpleField } from "../../../components/PurlpleText";
import { urlServidor } from "../../../methods/db";
import './PutUnidade.sass'

export default function PutUnidade(props: any) {

    const [unidade, setUnidade] = useState('')
    const [end_unidade, setEndUnidade] = useState('')

    async function putUnit(id: any, unidade: any, end_unidade: any) {

        try {
            await axios.put(`${urlServidor}/acessos/putDados/${id}`, {
                unidade: unidade,
                end_unidade: end_unidade
            })
                .then(() => {
                    alert('Unidade Atualizada com sucesso!')
                    return window.location.reload()
                })

        } catch (erro) {
            console.log(erro)
            return alert('Ocorreu um erro de rede, tente novamente mais tarde!')
        }
    }

    useEffect(() => {
        setUnidade(props.unity)
        setEndUnidade(props.end)
    },[props.unity, props.end])

    return (
        <div className="put-unidade-body-full">
            <div className="put-unidade-body">
                <h2 className="put-unidade-title">Atualizar Unidade</h2>

                <div className="put-unidade-inputs">

                    <PurpleField
                        fullWidth
                        label={<p>Cidade</p>}
                        variant="outlined"
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}

                    />
                    <PurpleField
                        fullWidth
                        label={<p>Endereço</p>}
                        variant="outlined"
                        value={end_unidade}
                        onChange={(e) => setEndUnidade(e.target.value)}

                    />

                </div>
                <div>
                    <Button onClick={() => putUnit(props.id, unidade, end_unidade)} fullWidth variant="contained">Atualizar</Button>
                </div>
            </div>
        </div>
    )
}