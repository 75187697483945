import axios from "axios";
import { urlServidor } from "../db";

export async function PutProductCard(uni: any, img: any, title: any, description: any, stock: any, money: any, id: any, pathImgold: any) {
   
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    };
   
    if (img === null) {

        const postProductDontImg = async () => {
            await axios.put(`${urlServidor}/produtos/geral/putProducts/${id}`, {
                title: title,
                description: description,
                stock: stock,
                money: money,
                unidadeId: uni,
            })
                .then(async () => {
                    alert('Produto Atualizado com sucesso!')
                    return window.location.reload()
                })

                .catch(async data => {

                    if (data.response.status === 400) {
                        return alert('Formulario não preenchido corretamente ou imagem não suportada, apenas PNG, JPG e JPEG')

                    }
                })
        }
        postProductDontImg()

    } else {

        await axios.post(`${urlServidor}/uploads/geral/post`, {
            file: img,
        }, config).then(data => {
            const turlImg = data
            if (turlImg != null) {
                const urlBd = turlImg.data.slice(31)

                let urlFe = `/uploads/geral/${urlBd}`
                const postProduct = async () => {
                    await axios.put(`${urlServidor}/produtos/geral/putProducts/${id}`, {
                        url_img: urlFe,
                        title: title,
                        description: description,
                        stock: stock,
                        money: money,
                        unidadeId: uni,
                    })
                        .then(async () => {

                            await axios.delete(`${urlServidor}/uploads/geral/delete/${pathImgold}`)

                            alert('Produto Atualizado com sucesso!')
                            return window.location.reload()

                        })
                        .catch(async data => {
                            await axios.delete(`${urlServidor}/uploads/geral/delete/${urlBd}`)

                            if (data.response.status === 400) {
                                return alert('Formulario não preenchido corretamente ou imagem não suportada, apenas PNG, JPG e JPEG')

                            }
                        })
                }
                postProduct()
            }
        })
    }

}