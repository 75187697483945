import axios from "axios";
import { urlServidor } from "../db";

export async function getCards(setDados: any, unidadeId: any) {
    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    };

    
    if (unidadeId !== 0) {
        return await axios.get(`${urlServidor}/produtos/geral/getProductsAll/${unidadeId}`, config)
            .then((data) => {
                setDados(data.data.card)
            })
    }
}
