import axios from "axios";
import { urlServidor } from "../db";


export async function PostProductCard(uni: any, img: any, title: any, description: any, stock: any, money: any) {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    };

    await axios.post(`${urlServidor}/uploads/geral/post`, {
        file: img,
    }, config).then(data => {
        const turlImg = data
        
        if (turlImg != null) {
            const urlBd = turlImg.data.slice(31) //1682354923178_profile_barbershop.jpg
            let urlFe = `/uploads/geral/${urlBd}`
            
            const postProduct = async () => {
                await axios.post(`${urlServidor}/produtos/geral/cadProducts`, {
                    url_img: urlFe,
                    title: title,
                    description: description,
                    stock: stock,
                    money: money,
                    unidadeId: uni,
                    
                })
                    .then(() => {
                        alert('Produto Cadastrado com sucesso!')
                        return window.location.reload()
                    })
                    .catch(async data => {
                        await axios.delete(`${urlServidor}/uploads/geral/delete/${urlBd}`)

                        if (data.response.status === 400) {
                            return alert('Formulario não preenchido corretamente ou imagem não suportada, apenas PNG, JPG e JPEG')

                        }
                    })
            }
            postProduct()
        }
    })
    .catch((data) => {
        if (data.response.status === 400) {
            return alert('Formulario não preenchido corretamente ou imagem não suportada, apenas PNG, JPG e JPEG')

        }
    })
}
