import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedLayoult } from './components/ProtectedLayoult';
import Admin from './pags/Admin/PainelAdm/Admin';
import PainelIntrutor from './pags/Coordenador/PainelInstrutor/PainelInstrutor';
import Login from './pags/Login/Login';
import Unidade from './pags/Unidade/Unidade';

function Rotas() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Unidade/>}/>
                <Route path='/Login' element={<Login />} />

                <Route path='/alvorada' element={
                    <ProtectedLayoult>
                        <Admin />
                    </ProtectedLayoult>
                } />

                <Route path='/PainelCoord' element={
                    <ProtectedLayoult>
                        <PainelIntrutor />
                    </ProtectedLayoult>
                } />
            </Routes>
        </BrowserRouter>
    )
}

export default Rotas;