import axios from "axios";
import { urlServidor } from "../db";

export async function delSolicitacaoCard(id: Number, pathImg: string) {

    await axios.delete(`${urlServidor}/produtos/geral/deleteProducts/${id}`)
        .then(async () => {
            await axios.delete(`${urlServidor}/uploads/geral/delete/${pathImg}`)
                .then( () => {
                    alert('Solicitacão Finalizada com sucesso')
                    return window.location.reload()
                })
                .catch(() => alert('Ocorreu um erro de Rede, tente novamente!'))

        })
        .catch(() => alert('Ocorreu um erro de Rede, tente novamente!'))
}
