import axios from "axios";
import { urlServidor } from "../db";

export async function getCard(setDados: any, id: any) {

    return await axios.get(`${urlServidor}/produtos/geral/getProducts/${id}`)
        .then((data) => {
            return setDados(data.data)
        })
        .catch((erro) => console.log(erro))

}
