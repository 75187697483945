import { Button, styled } from "@mui/material";

export const ButtonPrimary = styled(Button)`
    background: white;
    color: #311042;
    &:hover  {
        background: #d0d0d0;
        color: #311042;
    }

`

export const ButtonReject = styled(Button)`
    border-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 7px;
    border-bottom: 4px solid #ffffff;
    background: #E50051;
    color: #ffffff;
    &:hover  {
        background: #950236;
        color: white;
    }

`

export const ButtonSecondary = styled(Button)`
    color: white;
    border-color: white;
    &:hover  {
        border-color: #311042;
        background: white;
        color: #311042;
    }

`

export const ButtonThird = styled(Button)`
    color: white;
    border-color: white;
    margin: 10px;
    &:hover  {
        border-bottom: 4px solid #E50051;
        color: #E50051;
    }

`
export const ButtonFourd = styled(Button)`
    color: #311042;
    border-color: white;
    padding: 6%;
    margin: 1%;
    border-radius: 12px;
    background-color: white;
    &:hover  {
        background-color: #E50051;
        color: #fff;
    }

`
export const ButtonEdit = styled(Button)`
    color: #fff;
    border-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 7px;
    border-bottom: 4px solid #ffffff;
    background-color: #ff890a;
    &:hover  {
        background-color: #924d04;
        border-bottom: 4px solid #924d04;
        color: #fff;
    }

`

export const ButtonSix = styled(Button)`
    color: #fff;
    border-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 7px;
    border-bottom: 4px solid #ffffff;
    background-color: red;
    &:hover  {
        background-color: #E50051;
        border-bottom: 4px solid #E50051;
        color: #fff;
    }

`

export const ButtonDelete = styled(Button)`
    color: #fff;
    background-color: #E50051;
    margin-right: 20px;
    &:hover  {
        background-color: #a51c4c;
        color: #fff;
    }

`

export const ButtonRegistro = styled(Button)`
    background: #E50051;
    color: white;
    margin: 10px;
    &:hover  {
        background: white;
        color: #311042;
    }

`

export const ButtonProdutos = styled(Button)`
    background: #311042;
    color: white;
    margin: 10px;
    &:hover  {
        background: #2a0f38;
        color: #c2c2c2;
    }

`
export const ButtonAccept = styled(Button)`
    color: #fff;
    border-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 7px;
    border-bottom: 4px solid #ffffff;
    background-color: #311042;
    &:hover  {
        background-color: #21082d;
        border-bottom: 4px solid #ffffff;
        color: #fff;
    }

`