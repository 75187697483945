import { Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { PurpleField } from "../../../components/PurlpleText";
import { urlServidor } from "../../../methods/db";
import './PostUnidade.sass'

export default function PostUnidade() {

    const [email,setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [unidade, setUnidade] = useState('')
    const [end_unidade, setEndUnidade] = useState('')

    async function postUnit(email:any, password: any, unidade: any, end_unidade:any) {

        await axios.post(`${urlServidor}/acessos/cadAcessos`, {
            email: email,
            password: password,
            unidade: unidade,
            end_unidade: end_unidade
        })

        .then((data) => console.log(data))
        alert('Unidade Cadastrada com sucesso!')
    }


    return (
        <div className="punidade-body-full">
            <div className="punidade-body">
                <div className="punidade-header">
                    <h2>Cadastro de Unidade</h2>

                </div>
                <div className="punidade-inputs">
                    <PurpleField
                        fullWidth
                        label={<p>Login Unidade</p>}
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}

                    />
                    <PurpleField
                        fullWidth
                        label={<p>Senha</p>}
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}

                    />
                    <PurpleField
                        fullWidth
                        label={<p>Cidade</p>}
                        variant="outlined"
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}

                    />
                    <PurpleField
                        fullWidth
                        label={<p>Endereço</p>}
                        variant="outlined"
                        value={end_unidade}
                        onChange={(e) => setEndUnidade(e.target.value)}

                    />
                    
                </div>
                <div>
                    <Button onClick={() => postUnit(email,password,unidade,end_unidade)} fullWidth variant="contained">Cadastrar</Button>
                </div>
            </div>
        </div>
    )
}