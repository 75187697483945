import { Button, Divider, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { PurpleField } from "../../../components/PurlpleText";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { PutProductCard } from "../../../methods/put/PutProductCard";
import './PutProduct.sass'
import { urlServidor } from "../../../methods/db";
import codeCoin from '../../../imgs/code-coin.png'

export default function PutProduct(props: any) {

    const [img, setImg] = useState<File | null>(null)
    const [title, setTitle] = useState(props.title)
    const [description, setDescription] = useState(props.description)
    const [stock, setStock] = useState(props.stock)
    const [money, setMoney] = useState(props.money)
    const [uni, setUni] = useState('')
    const id = props.id

    const imgDefault = urlServidor + props.img
    const pathImgold = imgDefault.slice(44)


    useEffect(() => {
    },[props.uni])

    return (
        <div className="pproduct-body-full-edit">
            <div className="pproduct-body-edit">
                <div className="pproduct-title-edit">
                    <h4>Editar Produto</h4>
                </div>

                <div className="pproduct-body-form">
                    <form className="pproduct-body-form-inputs">
                        <h3>Formulario</h3>
                        <Divider color='white' />
                        <div>

                            {img ?
                                <IconButton aria-label="upload picture" component="label">
                                    <input hidden accept="image/*" name='img1' type="file"
                                        onChange={e => {
                                            if (e.target.files != null) {
                                                setImg(e.target.files[0])
                                            }
                                        }}
                                    />
                                    <AddAPhotoIcon sx={{ fontSize: 70, color: "white", mt: '12px' }} />
                                </IconButton>

                                :
                                <IconButton aria-label="upload picture" component="label">
                                    <input hidden accept="image/*" name='img1' type="file"
                                        onChange={e => {
                                            if (e.target.files != null) {
                                                setImg(e.target.files[0])
                                            }
                                        }}
                                    />
                                    <AddAPhotoIcon sx={{ fontSize: 70, color: "white", mt: '12px' }} />
                                </IconButton>
                            }
                        </div>
                        <div className="pproduct-inputs">
                            <PurpleField
                                fullWidth
                                label={<p>Titulo</p>}
                                variant="outlined"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                inputProps={{ maxLength: 18 }}
                            />
                            <PurpleField
                                label={<p>Descrição</p>}
                                variant="outlined"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                inputProps={{ maxLength: 180 }}
                            />
                            <PurpleField
                                label={<p>Estoque</p>}
                                variant="outlined"
                                value={stock}
                                onChange={e => setStock(e.target.value)}
                                inputProps={{ maxLength: 2 }}
                            />
                            <PurpleField
                                label={<p>CodeCoins</p>}
                                variant="outlined"
                                value={money}
                                onChange={e => setMoney(e.target.value)}
                                inputProps={{ maxLength: 4 }}
                            />
                        </div>
                        <Button onClick={() => PutProductCard(uni, img, title, description, stock, money, id, pathImgold)} fullWidth variant="contained">Enviar</Button>
                    </form>

                    <div className="pproduct-title-edit-mobile">
                        <h4>Editar Produto</h4>
                    </div>

                    <div className="pproduct-card">
                        <Card sx={{
                            width: '100%',
                            borderRadius: '14px',
                            backgroundColor: '#3f1953',
                            border: 'solid 2px #311042',
                            '@media (max-width:380px)': {
                                width: '100%',
                                height: '68vh'
                            }

                        }}>
                            <CardMedia
                                component="img"
                                height="190"
                                image={img ? URL.createObjectURL(img) : imgDefault}
                                alt="Recompensa"
                            />
                            <CardContent
                                sx={{
                                    backgroundColor: '#3f1953',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '28vh'
                                }}
                            >
                                <h2>{title}</h2>

                                <p>{description}</p>

                                <div className='card-stock'>
                                    <Inventory2Icon sx={{ color: 'white' }} />
                                    <h4>{stock}</h4>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <div className='card-coins'  >
                                         <img src={codeCoin} alt='codeCoin' style={{width: '50px', marginRight: '20px'}} />
                                        <h3>{money}</h3>
                                    </div>

                                    <Button size='small'
                                        sx={{
                                            color: '#3f1953',
                                            backgroundColor: '#fff',
                                            borderRadius: '12px',
                                            '&:hover': {
                                                backgroundColor: '#E50051',
                                                color: '#fff',
                                            }
                                        }} >Reservar</Button>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}