import React from "react"
import ChangePassword from "../pags/Admin/ChangePassword/ChangePassword"
import GetAlunosAdd from "../pags/Admin/getAlunosAdd/GetAlunosAdd"
import GetAlunosSub from "../pags/Admin/getAlunosSub/GetAlunosSub"
import PedidosWaiting from "../pags/Admin/PedidosWaiting/PedidosWaiting"
import PostProduct from "../pags/Admin/PostProduct/PostProduct"
import PostUnidade from "../pags/Admin/PostUnidade/PostUnidade"
import ProductsWaiting from "../pags/Admin/ProductsWaiting/ProductsWaiting"
import ViewProducts from "../pags/Admin/ViewProducts/ViewProducts"
import { GetSeveralAlunosAdd } from "../pags/Admin/getSeveralAlunosAdd/GetSeveralAlunosAdd"

export function switchScreen(numberPag: Number, nameUnid?: string) {
    switch (numberPag) {
        case 0:
            return <GetAlunosAdd nameUnid={nameUnid}/>
        case 1:
            return <GetAlunosSub nameUnid={nameUnid} />
        case 2:
            return <PostUnidade />
        case 3:
            return <PostProduct />
        case 4:
            return <ViewProducts />
        case 5: 
            return <ProductsWaiting />
        case 6:
            return <PedidosWaiting />
        case 7: 
            return <ChangePassword />
        case 8:
            return <GetSeveralAlunosAdd nameUnid={nameUnid}/>
    }
}