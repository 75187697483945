import { Fade, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Backdrop from '@mui/material/Backdrop';
import React, { useState } from "react";
import { PropsModal } from "./types";
import { PurpleField } from "../../PurlpleText";
import {ButtonRegistro} from "../../ButtonsCustom";
import { getUser } from "../../../methods/get/getUser";
import { PostSolicitacao } from "../../../methods/post/PostSolicitacao";


export function ResgatarModel(props: PropsModal) {

    const [username, setUsername] = useState('')
    const [user, setUser] = React.useState({
        fullname: String,
        city: String,
        customfields: [{}, {}, {}, {}, {}, { value: String }],
        id: Number
    });

    const [bdCodeBanck, setBdCodeBanck] = React.useState({
        id_aluno_moodle: Number,
        saldo: Number,
        nome_aluno: String
    });

    const [showLinks, setShowLinks] = useState(true)
    const showItems = () => setShowLinks(false)

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: '#311042',
        borderRadius: '12px',
        boxShadow: 24,
        p: 4,
        '@media (max-width: 900px)': {
            width: '70%'
        }
    };


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                {showLinks ? (
                    <Box sx={style}>

                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Já escolheu seu item ?
                        </Typography>

                        <Typography id="transition-modal-description" sx={{ mt: 2, }}>
                            Digite seu Login do AVA para realizar uma reserva!
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '20vh', justifyContent: 'space-around' }}>
                            <PurpleField
                                fullWidth
                                label={<p>Login</p>}
                                variant="outlined"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>

                        <ButtonRegistro
                            fullWidth
                            onClick={() => getUser(username, setBdCodeBanck, setUser, showItems)}
                        >
                            Reservar
                        </ButtonRegistro>

                    </Box>
                ) : (
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Concluir reseva do item {props.title}, no valor de {props.money} CodeCoins para {user.fullname.toString()} ?
                        </Typography>
                        <Typography>
                            Lembre-se de ter as CodeCoins necessarias para esta reserva!
                        </Typography>
                        <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                            <ButtonRegistro onClick={() => PostSolicitacao(username,props.id,props.uni,bdCodeBanck.saldo,props.money,bdCodeBanck.id_aluno_moodle) } >Sim</ButtonRegistro>
                            <ButtonRegistro onClick={() => setShowLinks(true)}>Não</ButtonRegistro>
                        </div>
                    </Box>
                )}
            </Fade>
        </Modal>
    )
}