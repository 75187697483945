import { ThemeProvider } from '@emotion/react';
import { useState } from 'react';
import './App.css';
import theme from './components/theme';
import { AuthProvider } from './context/AuthProvider';
import { NavContext } from './context/NavProvider';
import Rotas from './Routes';

function App() {

    const [numberPag, setNumberPag] = useState<number>(0)

    return (
        <div>
            <NavContext.Provider value={{ numberPag, setNumberPag }}>
                <AuthProvider>
                    <ThemeProvider theme={theme}>
                        <Rotas />
                    </ThemeProvider>
                </AuthProvider>
            </NavContext.Provider>
        </div>
    );
}

export default App;
