import { Backdrop, Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";
import DelProduct from "../../../pags/Admin/DelProduct/DelProduct";
import { PropsDelete } from "./types";

export function DeleteModal(props: PropsDelete) {

    const styleDelete = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '35%',
        bgcolor: '#311042',
        borderRadius: '12px',
        boxShadow: 24,
        margin: 0,
        '@media (max-width: 900px)': {
            width: '85%'
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.openDelete}
            onClose={props.handleCloseDelete}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.openDelete}>
                <Box sx={styleDelete}>
                    <DelProduct
                        handleCloseDelete={props.handleCloseDelete}
                        img={props.url_img}
                        id={props.id}
                    />
                </Box>
            </Fade>
        </Modal>
    )
}