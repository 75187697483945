import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Backdrop, Modal, TableHead } from "@mui/material";
import {
  ButtonDelete,
  ButtonProdutos,
} from "../../../components/ButtonsCustom";
import axios from "axios";
import { urlServidor } from "../../../methods/db";
import ChangePasswordUni from "../../../components/Modals/ChanegePasswordUni/ChangePasswordUni";
import PutUnidade from "../PutUnidade/PutUnidade";
import DeleteUnity from "../../../components/Modals/DeleteUnity/DeleteUnity";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

interface Dados {
  id: number;
  email: string;
  unidade: string;
  end_unidade: string;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function ChangePassword() {
  const [dados, setDados] = React.useState<Dados[]>([
    {
      id: 0,
      email: 'Carregando...',
      unidade: 'Carregando...',
      end_unidade: 'Carregando...',
    },
  ]);

  const [selectedEmail, setSelectedEmail] = React.useState("");
  const [selectedId, setSelectedId] = React.useState("");
  const [selectedUnity, setSelectedUnity] = React.useState("");
  const [selectedEnd, setSelectedEnd] = React.useState("");

  React.useEffect(() => {
    getAcessos();
  }, []);

  async function getAcessos() {
    await axios
      .get(`${urlServidor}/acessos/getAcessos`)
      .then((data) => setDados(data.data));
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dados.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [openDel, setOpenDel] = React.useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "25%",
    bgcolor: "#311042",
    borderRadius: "12px",
    boxShadow: 24,
    padding: "30px",
    "@media (max-width: 900px)": {
      width: "75%",
    },
  };

  return (
    <div className="pedidos-body">
      <div className="pedidos-body-limit">
        <div className="pedidos-body-table">
          <h2>Alterar senha de unidade</h2>

          <TableContainer component={Paper}>
            <Table
              stickyHeader
              sx={{ minWidth: 100 }}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Login da Unidade</TableCell>
                  <TableCell>Ações</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? dados.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : dados
                ).map((dados) => (
                  <TableRow key={dados.id.toString()}>
                    <TableCell component="th" scope="row">
                      {dados.email.toString()}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <ButtonProdutos
                        onClick={() => {
                          setSelectedId(dados.id.toString());
                          setSelectedUnity(dados.unidade);
                          setSelectedEnd(dados.end_unidade);
                          handleOpenEdit();
                        }}
                      >
                        Editar Unidade
                      </ButtonProdutos>
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <ButtonDelete
                        onClick={() => {
                          setSelectedEmail(dados.email);
                          handleOpen();
                        }}
                      >
                        Alterar Senha
                      </ButtonDelete>
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <ButtonDelete
                        onClick={() => {
                          setSelectedId(dados.id.toString());
                          handleOpenDel();
                        }}
                      >
                        Apagar
                      </ButtonDelete>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={dados.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <ChangePasswordUni email={selectedEmail} handleClose={handleClose} />
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <PutUnidade
            id={selectedId}
            unity={selectedUnity}
            end={selectedEnd}
            handleCloseEdit={handleCloseEdit}
          />
        </Box>
      </Modal>

      <Modal
        open={openDel}
        onClose={handleCloseDel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <DeleteUnity handleCloseDel={handleCloseDel} idselect={selectedId} />
        </Box>
      </Modal>
    </div>
  );
}
