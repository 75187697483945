import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../imgs/logo.png'
import '../Header/Header.sass'
import { styled } from '@mui/system';
import { ButtonSecondary, ButtonThird } from '../ButtonsCustom';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const AppBarCustom = styled(AppBar)`
    background-color: #311042;
    box-shadow: none;
    width: 100%;
    display: flex;  
    align-items: center;
    justify-content: center;
    height: 8vh;
    overflow: hidden;

    @media screen and (max-width: 900px) {
        height: 9vh;
    }

`

const ToolbarCustom = styled(Toolbar)`
    background-color: #311042;
    width: 90%;
    max-width: 980px;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
        justify-content: space-between;
        width: 90%;
        align-items: center;
        
    }

`

function DrawerAppBar() {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{
            textAlign: 'center',
            backgroundColor: '#311042',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Link to='/'><img className='logo-header-mob' src={logo} alt='logo' width='170px' height='35px' /></Link>

            <hr style={{ color: 'white', width: '80%' }} />
            <List>
                <ListItemButton sx={{ display: 'flex', flexDirection: 'column' }}>
                    <a style={{textDecoration: 'none'}} href='https://ava.code8734.com.br/'><ButtonThird>AVA</ButtonThird></a>
                    <a style={{textDecoration: 'none'}} href='https://code8734.com.br/'><ButtonThird>CODE8734</ButtonThird></a>
                    <a style={{textDecoration: 'none'}} href='https://code8734.com.br/sobre-a-code8734/'><ButtonThird>QUEM SOMOS</ButtonThird></a>
                    <Link style={{textDecoration: 'none'}} to='/'><ButtonThird>RECOMPENSAS</ButtonThird></Link>
                    <Link style={{textDecoration: 'none'}} to='/Login'><ButtonSecondary>Login</ButtonSecondary></Link>
                </ListItemButton>
            </List>
        </Box>
    );


    return (
        <div style={{ display: 'flex' }}>
            <AppBarCustom position='static'>
                <ToolbarCustom>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: 'none' }, }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Link to='/'><img className='logo-header' src={logo} alt='logo' width='200px' height='35px' /></Link>

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <a style={{textDecoration: 'none'}} href='https://ava.code8734.com.br/'><ButtonThird>AVA</ButtonThird></a>
                        <a style={{textDecoration: 'none'}} href='https://code8734.com.br/'><ButtonThird>CODE8734</ButtonThird></a>
                        <a style={{textDecoration: 'none'}} href='https://code8734.com.br/sobre-a-code8734/'><ButtonThird>QUEM SOMOS</ButtonThird></a>
                        <Link style={{textDecoration: 'none'}} to='/'><ButtonThird>RECOMPENSAS</ButtonThird></Link>
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Link style={{textDecoration: 'none'}} to='/Login'><ButtonSecondary>Login</ButtonSecondary></Link>
                    </Box>
                    <div className='invisivel'/> 
                </ToolbarCustom>
            </AppBarCustom>

            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>

        </div>
    );
}

export default DrawerAppBar;
