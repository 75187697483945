import axios from "axios";
import { urlServidor } from "../db";

export async function delSolicitacao( id: number) {
    
    await axios.delete(`${urlServidor}/geral/deleteSolicitacao/${id}`)
        .then(() => {
            alert('Solicitacão Finalizada com sucesso')
            return window.location.reload()
        })
        .catch(() => alert('Ocorreu um erro de Rede, tente novamente!'))
}