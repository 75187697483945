import axios from "axios";
import { urlServidor } from "../db";

export async function getSolicitacoes(setDados: any, id_unidade: any) {
    
    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    };

    await axios.get(`${urlServidor}/geral/getSolicitacoes/${id_unidade}`, config)
        .then((dados) => setDados(dados.data))
        .catch((erro) => console.log(erro))

}