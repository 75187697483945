import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import "./PedidosWaiting.sass";
import { getSolicitacoes } from "../../../methods/get/getSolicitacoes";
import { getUnidadeLocal } from "../../../methods/get/getUnidadeLocal";
import { Backdrop, Modal, TableHead } from "@mui/material";
import {
  ButtonDelete,
  ButtonProdutos,
} from "../../../components/ButtonsCustom";
import CardsAtivos from "../../../components/Card/CardsAtivos";
import { getCard } from "../../../methods/get/getCard";
import { PutStock } from "../../../methods/put/PutStock";
import { delSolicitacao } from "../../../methods/delete/delSolicitacao";
import axios from "axios";
import { urlServidor } from "../../../methods/db";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

interface Dados {
  id: number;
  username: string;
  id_product: number;
  id_moodle: number;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function PedidosWaiting() {
  const [unidade, setUnidade] = React.useState("");
  const [dados, setDados] = React.useState<Dados[]>([
    {
      id: 0,
      username: "Carregando...",
      id_product: 0,
      id_moodle: 0,
    },
  ]);

  const [dadosCards, setDadosCards] = React.useState({
    card: {
      id: Number,
      url_img: String,
      title: String,
      description: String,
      money: Number,
      stock: Number,
      hide: Boolean,
      approved: Boolean,
    },
  });

  const [alunoDados, setAlunoDados] = React.useState({
    fullname: String,
    customfields: [
      {},
      {},
      {},
      { value: String },
      { value: String },
      {},
      {},
      {},
      { value: String },
    ],
  });

  async function buildCard(i: any) {
    await getCard(setDadosCards, i);
    handleOpen();
  }

  let resposta = {
    card: {
      id: Number,
      url_img: String,
      title: String,
      description: String,
      money: Number,
      stock: Number,
      hide: Boolean,
      approved: Boolean,
    },
  };

  let saldo = {
    [0]: {
      saldo: Number,
    },
  };

  async function setStock(idCard: any, idSolicitacao: any, id_moodle: any) {
    await axios
      .get(`${urlServidor}/alunos/getIdsFild/${id_moodle}`)
      .then((data) => (saldo = data.data));

    await axios
      .get(`${urlServidor}/produtos/geral/getProducts/${idCard}`)
      .then((data) => {
        resposta = data.data;
        return resposta;
      })
      .catch((erro) => console.log(erro));

    if (+resposta.card.stock === 0) {
      return alert("Não há estoque, para finalizar o pedido");
    } else {
      if (+saldo[0].saldo >= +resposta.card.money) {
        const saldoCc = +saldo[0].saldo - +resposta.card.money;

        let stockFinal = +resposta.card.stock - 1;
        PutStock(
          unidade,
          idCard,
          stockFinal,
          idSolicitacao,
          saldoCc,
          id_moodle
        );
      } else {
        return alert(
          "O usuario não possui as CodeCoins necessarias para esta solicitação!"
        );
      }
    }
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dados.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    getUnidadeLocal(setUnidade);
    getSolicitacoes(setDados, unidade);
  }, [unidade]);

  //ModalProduto
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //ModalAluno
  const [openAluno, setOpenAluno] = React.useState(false);
  const handleOpenAluno = () => setOpenAluno(true);
  const handleCloseAluno = () => setOpenAluno(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "25%",
    bgcolor: "#311042",
    borderRadius: "12px",
    boxShadow: 24,
    padding: "30px",
    "@media (max-width: 900px)": {
      top: "70%",
      width: "80%",
      height: "86vh",
      overflowY: "auto",
      borderTopLeftRadius: "40px",
      borderTopRightRadius: "40px",
    },
    "@media (max-width: 380px)": {
      top: "65%",
    },
  };

  const styleAluno = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#311042",
    borderRadius: "12px",
    width: "25%",
    boxShadow: 24,
    padding: "30px",
    "@media (max-width: 900px)": {
      width: "70%",
    },
  };

  return (
    <div className="pedidos-body">
      <div className="pedidos-body-limit">
        <div className="pedidos-body-table">
          <h1>Solicitações de Items</h1>

          <TableContainer component={Paper}>
            <Table
              stickyHeader
              sx={{ minWidth: 100 }}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Login do aluno</TableCell>
                  <TableCell>Ações</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? dados.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : dados
                ).map((dados) => (
                  <TableRow key={dados.id.toString()}>
                    <TableCell component="th" scope="row">
                      {dados.username.toString()}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <a
                        target="_blank"
                        href={`https://ava.code8734.com.br/user/profile.php?id=${dados.id_moodle}`}
                      >
                        <ButtonProdutos>Ver Aluno</ButtonProdutos>
                      </a>
                    </TableCell>

                    <TableCell style={{ width: 160 }} align="right">
                      <ButtonProdutos
                        onClick={() => buildCard(dados.id_product)}
                      >
                        Ver produto
                      </ButtonProdutos>
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <ButtonDelete
                        onClick={() =>
                          setStock(dados.id_product, dados.id, dados.id_moodle)
                        }
                      >
                        Finalizar
                      </ButtonDelete>
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <ButtonDelete onClick={() => delSolicitacao(dados.id)}>
                        Cancelar
                      </ButtonDelete>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={dados.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <CardsAtivos
            key={dadosCards.card.id}
            title={dadosCards.card.title}
            url_img={dadosCards.card.url_img}
            description={dadosCards.card.description}
            money={dadosCards.card.money}
            stock={dadosCards.card.stock}
            id={dadosCards.card.id}
            hide={dadosCards.card.hide}
            approved={dadosCards.card.approved}
          />
        </Box>
      </Modal>

      <Modal
        open={openAluno}
        onClose={handleCloseAluno}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={styleAluno}>
          <h1 className="title-dados-aluno">Dados do aluno</h1>
          <div className="body-dados-aluno">
            <h1>{alunoDados.fullname.toString()}</h1>
            {alunoDados &&
            alunoDados.customfields &&
            alunoDados.customfields[3] &&
            alunoDados.customfields[3].value ? (
              <h1>{alunoDados.customfields[3].value.toString()}</h1>
            ) : (
              <h1>Nenhum valor encontrado</h1>
            )}
            {alunoDados &&
            alunoDados.customfields &&
            alunoDados.customfields[4] &&
            alunoDados.customfields[4].value ? (
              <h1>{alunoDados.customfields[4].value.toString()}</h1>
            ) : (
              <h1>Nenhum valor encontrado</h1>
            )}
            {alunoDados &&
            alunoDados.customfields &&
            alunoDados.customfields[8] &&
            alunoDados.customfields[8].value ? (
              <h1>{alunoDados.customfields[8].value.toString()}</h1>
            ) : (
              <h1>Nenhum valor encontrado</h1>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
