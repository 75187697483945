import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";
import { PropsRejeitar } from "./types";
import './Hide.sass'
import { ButtonDelete } from "../../ButtonsCustom";
import { delSolicitacaoCard } from "../../../methods/delete/delSolicitacaoCard";

export function RejeitarCard(props: PropsRejeitar) {
    const pathOldImg = props.pathImg.slice(15)
    const id = props.id

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: '#311042',
        borderRadius: '12px',
        boxShadow: 24,
        p: 4,
        '@media (max-width: 900px)': {
            width: '70%'
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.openReject}
            onClose={props.handleCloseReject}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.openReject}>
                <Box sx={style}>
                    <div className="hide-title">
                        <h3> Produto </h3>
                    </div>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Tem certeza que deseja rejeitar este produto ?
                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2, }}>
                        O mesmo sera deletado das solicitações.
                    </Typography>
                    <div style={{ display: 'flex', margin: '10px', justifyContent: 'flex-end' }}>
                        <ButtonDelete onClick={() => delSolicitacaoCard(id, pathOldImg)} sx={{ margin: '10px' }} variant="contained" color='inherit'>Rejeitar</ButtonDelete>
                        <Button onClick={props.handleCloseReject} sx={{ margin: '10px' }} variant='outlined'>Sair</Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}