import React from "react";
import { ButtonFourd } from "../../../components/ButtonsCustom";
import HeaderInstructor from "../../../components/HeaderInstrutor/HeaderInstrutor";
import { useNavContext } from "../../../context/NavProvider";
import { getUnidade } from "../../../methods/get/getUnidade";
import { switchScreen } from "../../../methods/switchScreen";
import './PainelInstrutor.sass'
import { Divider } from "@mui/material";

export default function Admin() {

    const [nameUnid, setNameUnid] = React.useState('Não Identificada')
    const [end, setEnd] = React.useState('Não Identificado')


    const {numberPag, setNumberPag} = useNavContext()

    React.useEffect(() => {
        getUnidade(setNameUnid, setEnd)
    }, [])

    return (
        <div >
            <div className='pn-body'>
                <HeaderInstructor unityButtons/>
                <div className='pn-menu-and-body'>
                    <div className='pn-menu-left'>
                        <div className='pn-menu-left-buttons'>
                            <ButtonFourd onClick={() => setNumberPag(0)}>Atribuir CodeCoins</ButtonFourd>
                            <ButtonFourd onClick={() => setNumberPag(1)}>Retirar CodeCoins</ButtonFourd>
                            <ButtonFourd onClick={() => setNumberPag(8)}>Atribuir CodeCoins a Grupo</ButtonFourd>
                            <Divider sx={{width: '90%', alignSelf: 'center'}} color='white'/>
                            <ButtonFourd onClick={() => setNumberPag(3)}>Cadastrar Produto</ButtonFourd>
                            <ButtonFourd onClick={() => setNumberPag(4)}>Listar Produtos</ButtonFourd>
                            <ButtonFourd onClick={() => setNumberPag(6)}>Listar Pedidos</ButtonFourd>

                        </div>
                        <div className='pn-menu-left-data'>
                            <h5>Unidade {nameUnid}</h5>
                            <h6>Endereço: {end}</h6>
                        </div>
                    </div>
                    {switchScreen(numberPag, nameUnid)}
                </div>
            </div>
        </div>
    );
}