import { Backdrop, Box, Fade, Modal } from "@mui/material";
import PutProduct from "../../../pags/Admin/PutProduct/PutProduct";
import { PropsModalEdit } from "./types";

export function EditarModal(props: PropsModalEdit) {

    const styleEdit = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        bgcolor: '#311042',
        borderRadius: '12px',
        boxShadow: 24,
        margin: 0,
        '@media (max-width: 900px)': {
            top: '60%',
            width: '100%',
            height: '86vh',
            overflowY: 'auto',
            borderTopLeftRadius: '40px',
            borderTopRightRadius: '40px'
        }, 
        '@media (max-width: 380px)' : {
            top: '59%'
        }
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.openEdit}
            onClose={props.handleCloseEdit}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.openEdit}>
                <Box sx={styleEdit}>
                    <PutProduct
                        img={props.url_img}
                        title={props.title}
                        description={props.description}
                        stock={props.stock}
                        money={props.money}
                        id={props.id}
                        uni={props.uni}
                    />
                </Box>
            </Fade>
        </Modal>
    )
}