import axios from "axios";
import { urlServidor } from "../../../methods/db";
import { ButtonDelete, ButtonPrimary } from "../../ButtonsCustom";
import './DeleteUnity.sass'

export default function DeleteUnity(props: any) {

    async function delUnity(id:string) {
        
        try {
                await axios.get(`${urlServidor}/produtos/geral/getProductsAll/${id}`)
                    .then((data) => {
                        const urlImg = data.data.card

                        urlImg.map( async (e: any) => {
                            let pathOld = e.url_img.slice(16)

                            await axios.delete(`${urlServidor}/uploads/geral/delete/${pathOld}`)
                                .then(() => console.log('Imagen Deletada'))
                                .catch((erro) => console.log(erro))
                        })

                    }).then( async () => {
                        await axios.delete(`${urlServidor}/produtos/geral/getProductsAll/DeleteAll/${id}`)
                            .then(() => console.log('Cards Deletados'))
                            .catch((erro) => console.log(erro))
                    }).then (async () => {
                        await axios.delete(`${urlServidor}/geral/getSolicitacoes/DeleteAll/${id}`)
                            .then(() => console.log('Solicitações Deletadas'))
                            .catch((erro) => console.log(erro))
                    }).then (async () => {
                        await axios.delete(`${urlServidor}/acessos/delAcessos/${id}`)
                            .then(() => {
                                alert('Unidade apagada com sucesso!')
                                return window.location.reload()
                            })
                            .catch(() => {
                                return alert('Ocorreu um erro de Rede, tente novamente mais tarde!')
                            }) 
                    })
          
        } catch (error) {
            console.log(error)
            return alert('Ocorreu um erro de Rede, tente novamente mais tarde!')
            
        }
        
    }

    return (
        <div className="del-uni-body" >
            <h1 className="del-uni-title">Apagar Unidade</h1>
            <div>
                <p>Tem certeza que deseja apagar esta unidade?</p>
            </div>
            <div className="del-uni-button">
                <ButtonDelete onClick={() => delUnity(props.idselect)}>Sim</ButtonDelete>
                <ButtonPrimary onClick={() => props.handleCloseDel()}>Não</ButtonPrimary>
            </div>
        </div>
    )
}