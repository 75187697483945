import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { PurpleField, PurpleFieldWhite } from "../../../components/PurlpleText";
import SearchIcon from "@mui/icons-material/Search";
import "./getSeveralAlunosAdd.sass";
import { getUser } from "../../../methods/get/getUser";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import manivela from "../../../imgs/manivela.png";
import axios from "axios";
import { urlServidor } from "../../../methods/db";

interface User {
  fullname: string;
  username: string;
  city: string;
  customfields: { value: string }[];
  id: number;
}

interface BdCodeBanck {
  id: number;
  id_aluno_moodle: number;
  saldo: number;
  nome_aluno: string;
}

interface UserAVA {
  id: number;
  username: string;
}

export function GetSeveralAlunosAdd(props: any) {
  const [usernameLocal, setUsernameLocal] = useState("");
  const [usernames, setUsernames] = useState<UserAVA[]>([]);
  const [textMessage, setTextMessage] = useState<string>("");
  const [saldoAdd, setSaldoAdd] = useState<number>();

  const [user, setUser] = useState<User>({
    fullname: "",
    username: "",
    city: "",
    customfields: [
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
      { value: "" },
    ],
    id: 0,
  });

  const [bdCodeBanck, setBdCodeBanck] = useState<BdCodeBanck>({
    id: 0,
    id_aluno_moodle: 0,
    saldo: 0,
    nome_aluno: "",
  });

  const [showLinks, setShowLinks] = useState(false);

  const showItems = () => setShowLinks(true);

  useEffect(() => {
    if (Object.keys(usernames).length === 10) {
      return alert(
        "O máximo de alunos possível é 10! Remova um deles ou realize uma nova operação!"
      );
    } else {
      setUsernames((prevState) => {
        const newUser = { id: user.id, username: user.username };

        if (
          newUser.id !== 0 &&
          newUser.username !== "" &&
          !prevState.some((existingUser) => existingUser.id === newUser.id)
        ) {
          return [...prevState, newUser];
        } else {
          return prevState;
        }
      });
    }
  }, [user]);

  const handleGetUser = useCallback(async () => {
    await getUser(usernameLocal, setBdCodeBanck, setUser, showItems);
    setUsernameLocal("");
  }, [usernameLocal]);

  function removeUser(index: number) {
    const updatedUsernames = [...usernames];

    if (index >= 0 && index < updatedUsernames.length) {
      updatedUsernames.splice(index, 1);
      setUsernames(updatedUsernames);
    }
  }

  async function putSaldo(saldoAdd: any, textMessage: string) {
    if (saldoAdd === undefined) {
      alert("Adicione o numero de CodeCoins necessarias!");
    }
    if (textMessage === "") {
      alert("Preencha uma justificativa valida!");
    } else {
      const finalIds = usernames.map((data) => data.id);
      const finalUsernames = usernames.map((data) => data.username);

      await axios.put(`${urlServidor}/alunos/updateBalanceGroup`, {
        ids: finalIds,
        saldo: saldoAdd,
      });

      await axios
        .post(`${urlServidor}/email/send-email`, {
          textmail: textMessage,
          unidade: props.nameUnid,
          alunos: finalUsernames,
        })
        .then(() => {
          return (
            alert(
              "E-mail enviado para Franchising, e moedas atribuidas aos Alunos!"
            ),
            window.location.reload()
          );
        })
        .catch((erro) =>
          alert("Ocorreu um erro ao enviar o e-mail a Franchising")
        );
    }
  }

  return (
    <div className="get-several-body">
      <div className="get-several-card">
        <div className="get-title">
          <h4>Adicionar CodeCoins a um grupo</h4>
        </div>
        <div className="get-serch-sev">
          <PurpleField
            label={
              <p style={{ display: "flex", alignItems: "center" }}>
                <SearchIcon />
                Pesquisar Alunos
              </p>
            }
            value={usernameLocal}
            fullWidth
            onChange={(e) => setUsernameLocal(e.target.value)}
          />
          <IconButton onClick={handleGetUser}>
            <SearchIcon
              sx={{
                color: "white",
                backgroundColor: "#E50051",
                padding: "15px",
                borderRadius: "12px",
                ":hover": {
                  backgroundColor: "white",
                  color: "#3f1953",
                },
              }}
            />
          </IconButton>
        </div>
        <div className="button-groups-several">
          {Object.keys(usernames).map((key) => {
            const index = parseInt(key);

            return (
              <button
                onClick={() => removeUser(index)}
                className="button-several-alunos"
                key={index}
              >
                <PersonRemoveIcon sx={{ marginRight: "10px" }} />{" "}
                {usernames[index].username}
              </button>
            );
          })}
        </div>

        {showLinks ? (
          <div className="get-info-aluno-sev">
            <div className="get-user-saldo-sev">
              <FormControl sx={{ marginBottom: "4%" }} fullWidth>
                <InputLabel color="secondary" id="demo-simple-select-label">
                  Adicionar CodeCoins
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={saldoAdd}
                  label="Age"
                  onChange={(e) =>
                    setSaldoAdd(parseInt(e.target.value.toString()))
                  }
                  color="secondary"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={75}>75</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={125}>125</MenuItem>
                </Select>
              </FormControl>
              {/* </div> */}
              {/* <div className="get-user-space-button"> */}
              <PurpleFieldWhite
                label="Justificativa p/ CodeCoin"
                variant="outlined"
                value={textMessage}
                fullWidth
                onChange={(e) => setTextMessage(e.target.value)}
              />
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => putSaldo(saldoAdd, textMessage)}
            >
              Adicionar ao saldo
            </Button>
          </div>
        ) : (
          <div className="get-info-aluno">
            <div className="get-user-manivela">
              <img src={manivela} width="200px" alt="manivela" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
