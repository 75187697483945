import axios from "axios";
import { urlServidor } from "../db";

export async function putHide(id: Number, hide: boolean) {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };

  await axios
    .get(`${urlServidor}/geral/getProductonSolicitacoes/${id}`, config)
    .then(async (data) => {
      if (data.status === 200) {
        alert(
          "Existem Solicitações pendentes, resolva-as antes de ocultar este produto!"
        );
        window.location.reload();
      }
    })
    .catch(async () => {
      await axios
        .put(`${urlServidor}/produtos/geral/hide/${id}`, {
          hide: hide,
        })
        .then(() => {
          alert("Produto alterado com sucesso!");
          return window.location.reload();
        })

        .catch(() => {
          alert("Ocorreu um erro, por favor tente novamente!");
        });
    });
}
