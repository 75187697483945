import axios from "axios";
import { token, urlServidor } from "../db";

async function postUserCodeBanck(id_aluno_moodle: any, nome_aluno: any, setBdCodeBanck: any) {
    await axios.post(`${urlServidor}/alunos/cadaluno`, {
        id_aluno_moodle: id_aluno_moodle,
        nome_aluno: nome_aluno
    })
        .then(function (response) {
            getIds(id_aluno_moodle, nome_aluno, setBdCodeBanck)
        })
        .catch(function (error) {
            console.log(error);
        });
}

export async function getIds(idMoodle: Number, nomeUser: String, setBdCodeBanck: any) {
    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    };

    await axios.get(`${urlServidor}/alunos/getIdsFild/${idMoodle}`, config)
        .then((data) => {
            if (!data.data.length) {
                postUserCodeBanck(idMoodle, nomeUser, setBdCodeBanck)

            } else {
                setBdCodeBanck(data.data[0])
            }
        })
}

// export async function getIds(idMoodle: Number, nomeUser: String, setBdCodeBank: any) {
//     const config = {
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//       }
//     };
  
//     await axios.get(`${urlServidor}/alunos/getIdsFild/${idMoodle}`, config)
//       .then((data) => {
//         if (!data.data.length) {
//             postUserCodeBanck(idMoodle, nomeUser, setBdCodeBank);
//         } else {
//           setBdCodeBank((prevData: any[]) => [...prevData, data.data[0]]);
//         }
//       });
//   }

export async function getUser(username: any, setBdCodeBanck: any, setUser: any, showItems: any) {

    try {
        await axios.get(`https://ava.code8734.com.br/webservice/rest/server.php?wstoken=${token}
        &wsfunction=core_user_get_users&moodlewsrestformat=json&criteria[0][key]=username&criteria[0][value]=${username}`)
            .then((response) => {
                if (response.data.users[0] === undefined) {
                    return (
                        alert('insira um usuario valido!')
                    )
                } else {
                    let idMoodle = response.data.users[0].id
                    let nomeUser = response.data.users[0].fullname

                    getIds(idMoodle, nomeUser, setBdCodeBanck)
                    setUser(response.data.users[0])
                    showItems()
                }
            })

    } catch (error) {
        console.log(error)
    }

}