import axios from "axios";
import { urlServidor } from "../db";

export async function delProductCard(id: any, url_img: any) {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };

  await axios
    .get(`${urlServidor}/geral/getProductonSolicitacoes/${id}`, config)
    .then(async (data) => {
      if (data.status === 200) {
        alert(
          "Existem Solicitações pendentes, resolva-as antes de excluir este produto!"
        );
        window.location.reload();
      }
    })
    .catch(async () => {
      await axios
        .delete(`${urlServidor}/produtos/geral/deleteProducts/${id}`, config)
        .then(async () => {
          await axios
            .delete(`${urlServidor}/uploads/geral/delete/${url_img}`)
            .then(async () => {
              alert("Produto Apagado com sucesso!");
              return window.location.reload();
            });
        });
    });
}
