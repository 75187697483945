import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";
import { putHide } from "../../../methods/put/putHide";
import { PropsAprovar } from "./types";
import './Hide.sass'
import { ButtonDelete } from "../../ButtonsCustom";
import { putApproved } from "../../../methods/put/PutAproved";

export function AprovarCard(props: PropsAprovar) {

    const id = props.id
    
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: '#311042',
        borderRadius: '12px',
        boxShadow: 24,
        p: 4,
        '@media (max-width: 900px)': {
            width: '70%'
        }
    };

    if (props.ativar === false) {

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.openAprovar}
                onClose={props.handleCloseAprovar}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openAprovar}>
                    <Box sx={style}>
                        <div className="hide-title">
                            <h3> Produto </h3>
                        </div>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Tem certeza que deseja Aprovar este produto ?
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, }}>
                            O mesmo estara disponivel na Pagina Inicial, e em Produtos Ativos.
                        </Typography>
                        <div style={{ display: 'flex', margin: '10px', justifyContent: 'flex-end' }}>
                            <ButtonDelete onClick={() => putHide(id, true)} sx={{ margin: '10px' }} variant="contained" color='inherit'>Ocultar</ButtonDelete>
                            <Button onClick={props.handleCloseAprovar} sx={{ margin: '10px' }} variant='outlined'>Sair</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        )
    } if (props.ativar === true) {

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.openAprovar}
                onClose={props.handleCloseAprovar}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.openAprovar}>
                    <Box sx={style}>
                        <div className="hide-title">
                            <h3>Aprovar Produto</h3>
                        </div>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Tem certeza que deseja Aprovar este produto ?
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2, }}>
                            O mesmo estara disponivel na Pagina Inicial, e em Produtos Ativos.
                        </Typography>
                        <div style={{ display: 'flex', margin: '10px', justifyContent: 'flex-end' }}>
                            <ButtonDelete onClick={() => putApproved(id, true)} sx={{ margin: '10px' }} variant="contained" color='inherit'>Aprovar</ButtonDelete>
                            <Button onClick={props.handleCloseAprovar} sx={{ margin: '10px' }} variant='outlined'>Sair</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        )

    } else {
        return (
            <div>ALERTA ERRO AQUI</div>
        )
    }
}