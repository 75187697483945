import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette:{
        primary:{
            main:'#ffffff'
        },
        secondary:{
            main:'#311042'
        }
    },
})

export default theme;