import { IconButton } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import logoBranca from '../../imgs/code8734_branca.jpg'
import './Footer.sass'

export default function Footer() {

    return (
        <div className="footer-full-body">
            <div className="footer-body">
                <a href='https://code8734.com.br/'><img id='footer-logo' src={logoBranca} width='500px' alt='logo code' /></a>
                <a href='https://code8734.com.br/'><img id='footer-logo-mob' src={logoBranca} width='300px' alt='logo code' /></a>
                <div>

                    <IconButton >
                        <a href="https://www.instagram.com/code8734br/"><InstagramIcon sx={{ color: 'white', fontSize: '45px' }} /></a>
                    </IconButton>
                    <IconButton>
                        <a href="https://www.facebook.com/code8734br/"><FacebookIcon sx={{ color: 'white', fontSize: '45px' }} /></a>
                    </IconButton>
                    <IconButton>
                        <a href="https://www.youtube.com/channel/UCpvLB_-OiA2GWeKkfqCgLig"><YouTubeIcon sx={{ color: 'white', fontSize: '45px' }} /></a>
                    </IconButton>
                </div>

                <p>Av. Rondon Pacheco 4175 | Uberlândia – MG | 38.400-242</p>
            </div>
        </div>
    )
}